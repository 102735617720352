.side-nav {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 1000;
  }
  
  .side-nav nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;  
  }
  
  .side-nav .cloud-item {
    text-decoration: none;
    color: #000000;
    transition: transform 0.3s ease;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 150px;  
    height: 80px; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    filter: brightness(1.2);  
  }
  
  .side-nav .cloud-item:hover {
    transform: scale(1.05);
  }
  
  .side-nav .item-number {
    font-weight: bold;
    margin-right: 0.5rem;
    font-size: 0.875rem;
  }
  
  .side-nav .item-label {
    font-size: 0.875rem;
  }
  
  .side-nav .cloud-item.active .item-number,
  .side-nav .cloud-item.active .item-label {
    font-weight: bold; 
    font-size: 1rem;
    color: #990011; 
  }
  
  .mini-cloud-trail {
    position: relative;
    height: 80px;  
    width: 50px;   
    margin-right: 25px;  
  }
  
  .mini-cloud {
    position: absolute;
    width: 20px;  
    height: 10px;  
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;  
  }
  
  @media (max-width: 768px) {
    .side-nav {
      right: 1rem;
      bottom: 1rem;
    }
    
    .side-nav .cloud-item {
      width: 120px; 
      height: 64px;
    }
  
    .mini-cloud-trail {
      height: 80px; 
      width: 80px;
      margin-right: 20px; 
    }
  
    .mini-cloud {
      width: 15px; 
      height: 7.5px;
    }
  }

  @media only screen and (max-width: 768px) {
    .side-nav {
        display: none;
    }
}