.welcome-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.cloud-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.cloud-background img {
    position: absolute;
}

.cloud-background .main-cloud {
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    height: auto;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.cloud-background .left-cloud,
.cloud-background .right-cloud {
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.cloud-background .left-cloud {
    top: 30%;
    left: 27%;
    transform: translate(-50%, -50%) scale(0.6);
}

.cloud-background .right-cloud {
    top: 20%;
    right: 32%;
    transform: translate(50%, -50%) scale(0.7);
}

.profile-picture {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.233);
    position: relative;
    z-index: 2;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

#nej.full-height-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.welcome-section h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
}

.welcome-section p {
    font-size: 1.2rem;
    position: relative;
    z-index: 2;
}

.full-height-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 0;
    box-sizing: border-box;
    overflow-y: auto;
}

#about.full-height-section {
    justify-content: flex-start;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    position: relative;
    z-index: 3;
}

.social-icons a {
    color: #000;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #990012;  
}

.section-divider {
    width: 80%;
    margin: 2rem auto;
    border: none;
    border-top: 1px solid #ccc;
}

.download-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    position: relative;
    z-index: 3;
}

.download-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #ffffff;
    color: #000000;
    border: 0px solid #000000; 
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 1rem;
}

.download-button:hover {
    background-color: #990012;
    color: #ffffff;
    border-color: #990012;
}

@media only screen and (max-width: 768px) {
    .welcome-section h1 {
        font-size: 2rem;
    }

    .welcome-section p {
        font-size: 1rem;
    }

    .profile-picture {
        width: 150px;
        height: 150px;
    }

    .social-icons {
        gap: 15px;
    }

    .cloud-background .left-cloud,
    .cloud-background .right-cloud {
        display: none;
    }

    .full-height-section {
        padding: 1rem 0;
    }

    .download-buttons {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 480px) {
    .welcome-section h1 {
        font-size: 1.5rem;
    }

    .profile-picture {
        width: 120px;
        height: 120px;
    }

    .full-height-section {
        padding: 0.5rem 0;
    }
}