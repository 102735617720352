.contact-container {
    padding: 80px 20px 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
}

.contact-title {
    color: #fff;
    font-size: 2.5rem;
    margin-bottom: 10px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.contact-subtitle {
    color: #ffffff;
    font-size: 1.2rem;
    margin-bottom: 30px;
    text-align: center;
}

.contact-form {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
}

.form-group {
    margin-bottom: 20px;
    width: 100%;
}

.form-group label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: #8794aa;
    font-weight: bold;
}

.form-group input,
.form-group textarea {
    width: calc(100% - 20px); 
    padding: 10px;
    border: 2px solid #e0e0e0;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #356b8d;
    outline: none;
}

.form-group textarea {
    height: 150px;
    resize: vertical;
}

.submit-btn {
    background-color: #356b8d;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.submit-btn:hover {
    background-color: #0B3954;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    color: #356b8d;
    margin-bottom: 10px;
}

.modal-content p {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #000;
}

.modal-content button {
    background-color: #356b8d;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-content button:hover {
    background-color: #0B3954;
}


@media (max-width: 768px) {
    .contact-title {
        font-size: 2rem;
    }

    .contact-subtitle {
        font-size: 1rem;
    }

    .contact-form {
        padding: 20px;
    }
}