.navbar {
    background-color: #fcf6f5;
    box-shadow: 0 2px 24px 0 rgba(255, 255, 255, 0.256);
    width: 100%;
    height: 80px; 
    display: flex;
    align-items: center;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 2rem;
    height: 100%;
    width: 100%;
}

.navbar-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.navbar-logo img {
    height: 50px;
    border-radius: 10px;
    margin-right: 1rem;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.navbar-name {
    font-weight: bold;
    color: #000000;
    font-size: 1.5rem;
    margin-right: 1rem;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block; 
}

.navbar-name span {
    display: inline-block;
}

.navbar-logo::after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 40px;
    background-color: #000000;
}

.navbar-menu {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;
}

.navbar-menu li {
    margin: 0;
    padding: 0 1rem;
    display: flex;
    align-items: center;
}

.navbar-menu a {
    color: #000000;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
    font-size: 1rem;
}

.navbar-menu a:hover {
    color: #990011;
}

.hamburger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger-line {
    width: 25px;
    height: 3px;
    background-color: #000000;
    margin: 3px 0;
    transition: 0.4s;
}

@media only screen and (max-width: 768px) {
    .navbar-menu {
        display: none;
        position: fixed;
        top: 85px;
        left: 0;
        width: 100%;
        height: calc(28vh - 80px);
        background-color: rgba(255, 255, 255, 0.8);
        flex-direction: column;
        overflow-y: auto;
        border-radius: 10px;
    }

    .navbar-menu.active {
        display: flex;
    }

    .navbar-menu li {
        margin: 10px 0;
        padding: 0px 0px 0px 10px;
        width: 100%;
    }

    .hamburger-menu {
        display: flex;
    }
}

@media only screen and (max-width: 480px) {
    .navbar-name {
        font-size: 1.2rem;
    }

    .navbar-logo img {
        height: 40px;
    }
}