.about-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: white;
}

.about-me-container h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.about-me-container > p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 600px;
}

.skill-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5rem;
}

.skill-card {
  width: 200px;
  height: 250px;
  perspective: 1000px;
  margin-bottom: 10px;
}

.skill-card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.skill-card:hover .skill-card-inner {
  transform: rotateY(180deg);
}

.skill-card-front, .skill-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.skill-card-front {
  background-color: #ffffff;
  color: #000;
}

.skill-card-back {
  background-color: #f0f0f0;
  color: #000;
  transform: rotateY(180deg);
}

.skill-card-front svg {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #990012;
}

.skill-card-front h3 {
  font-size: 1.2rem;
  color: #000;
}

.skill-card-front p, .skill-card-back p {
  font-size: 0.9rem;
  text-align: center;
  color: #000;
}

.achievement-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

@media only screen and (max-width: 1024px) {
  .skill-cards-container {
    gap: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .about-me-container h2 {
    font-size: 2rem;
  }
  
  .about-me-container > p {
    font-size: 1rem;
  }
  
  .skill-cards-container {
    gap: 2rem;
  }
}

@media only screen and (max-width: 480px) {
  .skill-cards-container {
    flex-direction: column;
    align-items: center;
  }
}