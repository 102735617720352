.construction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 1rem;
  }
  
  .construction-card {
    position: relative;
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 24rem;
    width: 100%;
  }
  
  .tape-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .tape {
    position: absolute;
    width: 150%;
    height: 4rem;
    background-color: #fbbf24;
  }
  
  .top-tape {
    top: 0;
    left: 0;
    transform: rotate(-12deg);
    transform-origin: left;
  }
  
  .bottom-tape {
    bottom: 0;
    right: 0;
    transform: rotate(12deg);
    transform-origin: right;
  }
  
  .content {
    position: relative;
    z-index: 10;
  }
  
  .icon {
    font-size: 4rem;
    text-align: center;
    margin-top: 10px;
    animation: bounce 1s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
  
  .title {
    font-size: 1.875rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    color: #000;
  }
  
  .text {
    color: #000;
    text-align: center;
    font-size: large;
    font-weight: 400;
    margin-bottom: 40px;
  }
  
  /* .button-container {
    text-align: center;
  }
  
  .notify-button {
    background-color: #f59e0b;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .notify-button:hover {
    background-color: #d97706;
  } */